/**
 * Created by Saber on 2015/6/18.
 */

/**
 * ["development", "staging", "production"], default to "development"
 * @type {string}
 */
var env = 'production';

/**
 * ["US", "CN"], default to "CN"
 * @type {string}
 */
var region = 'US';

(function (env, region) {

    var realModeQiniu = {
        'max': '2',
        'crop': '1'
    };

    var regional = {
        CN: {
            provider: {
                imageBases: {
                    'upload': {
                        url: 'https://asset-us.liaoyuan.io/<%= key %>?imageView/<%= mode %>/w/<%= widthInPx %>/h/<%= heightInPx %>',
                        mapping: {
                            mode: {
                                max: 2,
                                crop: 1
                            }
                        }
                    },

                    'upload-cn': {
                        url: 'https://asset-cn.liaoyuan.io/<%= key %>?imageView/<%= mode %>/w/<%= widthInPx %>/h/<%= heightInPx %>',
                        mapping: {
                            mode: {
                                max: 2,
                                crop: 1
                            }
                        }
                    }
                },
                mapUrl: 'https://www.google.cn/maps/place/John+B.+Hynes+Veterans+Memorial+Convention+Center/@42.3473804,-71.087138,17z/data=!3m1!5s0x89e37a0fe0de1597:0x97cbb37fa71416e0!4m12!1m6!3m5!1s0x89e37a052ccd1949:0x20700f43308eb220!2sHynes+Convention+Center!8m2!3d42.3479355!4d-71.0876744!3m4!1s0x0:0xbce6f9a63000697b!8m2!3d42.3469462!4d-71.08459'
            }
        },
        US: {
            provider: {
                imageBases: {
                    'upload': {
                        url: 'https://liaoyuanavatar.imgix.net/<%= key %>?fit=<%= mode %>&w=<%= widthInPx %>&h=<%= heightInPx %>'
                    },
                    'upload-cn': {
                        url: 'https://liaoyuan-asset-cn.imgix.net/<%= key %>?fit=<%= mode %>&w=<%= widthInPx %>&h=<%= heightInPx %>'
                    }
                },
                mapUrl: 'https://www.google.com/maps/place/John+B.+Hynes+Veterans+Memorial+Convention+Center/@42.3473804,-71.087138,17z/data=!3m1!5s0x89e37a0fe0de1597:0x97cbb37fa71416e0!4m12!1m6!3m5!1s0x89e37a052ccd1949:0x20700f43308eb220!2sHynes+Convention+Center!8m2!3d42.3479355!4d-71.0876744!3m4!1s0x0:0xbce6f9a63000697b!8m2!3d42.3469462!4d-71.08459'
            }
        }
    };

    var regionalConfig = regional[region];

    var imageTemplates = {};
    _.each(regionalConfig.provider.imageBases, function (imageBase, keyPrefix) {
        imageTemplates[keyPrefix] = _.template(imageBase.url);
    });
    var GetImageUrlWithCDN = function (key, mode, widthInPx, heightInPx) {
        var params = {
            key: key, mode: mode, widthInPx: widthInPx, heightInPx: heightInPx
        };
        var keyPrefix = key.split('\/')[0];
        if (!keyPrefix || !regionalConfig.provider.imageBases[keyPrefix]) {
            return 'assets/images/avatars/avatar-default.svg';
        }

        if (regionalConfig.provider.imageBases[keyPrefix].mapping) {
            for (var m in regionalConfig.provider.imageBases[keyPrefix].mapping) {
                params[m] = regionalConfig.provider.imageBases[keyPrefix].mapping[m][params[m]];
            }
        }
        return imageTemplates[keyPrefix](params);
    };

    var serverUrl = {
        development: 'http://dev.liaoyuan.io:2000',
        staging: 'http://dev.liaoyuan.io:2000',
        production: 'https://api.liaoyuan.io'
    };

    var frontUrl = {
        development: 'https://liaoyuan.io',
        staging: 'https://liaoyuan.io',
        production: 'https://liaoyuan.io'
    };

    var summitTeamFeedIds = {
        development: {
            zhTeamFeedId: '57ad06647b864cd5099fa625',
            enTeamFeedId: '56e1d2925649a950045535df',
            zhMediaFeedId: '57c7348094a7b6ad0b37484b',
            enMediaFeedId: '57c734d794a7b6ad0b374850',
            zhPartnerFeedId: '57c741ed420d9c2a3ade231a',
            enPartnerFeedId: '57c7424b420d9c2a3ade231d'
        },
        staging: {
            zhTeamFeedId: '57b4d49a9584329639db7042',
            enTeamFeedId: '57b4d6929584329639db7065',
            zhMediaFeedId: '57c74122420d9c2a3ade230f',
            enMediaFeedId: '57c741ae420d9c2a3ade2317',
            zhPartnerFeedId: '57c741ed420d9c2a3ade231a',
            enPartnerFeedId: '57c7424b420d9c2a3ade231d'
        },
        production: {
            zhTeamFeedId: '59b8a40021c46a4cde316fac',
            enTeamFeedId: '59b84bdb21c46a4cde315446',
            zhMediaFeedId: '59b83b1d21c46a4cde3151a9',
            enMediaFeedId: '59b86afde71b0134ecfd66a4',
            zhPartnerFeedId: '59adbf6a06422d69a39ca08d',
            enPartnerFeedId: '59aed73d06422d69a39ca6fc'
        }
    };

    var target = {
        apply: '/utility/summitApply',
        randomTeam: 'random',
        weixinSignature: '/weixin/signature'
    };

    var weixinShareParams = {
        title: '正在售票 | 2017 第二届中美投融资峰会即将在北美揭幕',
        desc: '早鸟售票火热进行中！本次峰会由「燎原」主办，将于2017年9月30日-10月1日在波士顿揭开帷幕！中美创投精英携手数百个优质海归岗位等你来挑战！',
        only: '正在售票 | 2017 第二届 中美投融资峰会即将在北美揭幕',
        link: 'https://summit.liaoyuan.io/',
        imgUrl: 'https://summit.liaoyuan.io/assets/images/weixin.png'
    };

    angular.module('summit').constant('Config', {
        TicketFeedUrl: 'https://liaoyuan.io/feed/59961ddc3ea9c333c3aa5048/',
        VenueMapsUrl: regionalConfig.provider.mapUrl,
        GetImageUrlWithCDN: GetImageUrlWithCDN,
        ServerUrl: serverUrl[env],
        FrontUrl: frontUrl[env],
        Target: target,
        SummitTeamFeedIds: summitTeamFeedIds[env],
        WeixinShareParams: weixinShareParams
    });
})(env, region);

